import React from "react"
import { graphql } from "gatsby"
import { Layout, Seo, Theme } from "../components"
import { Styles2 } from "./styles"


const ThanksTemplate = ({ data, pageContext }) => {
  const { contactFields } = data.page
  const thanks = contactFields.thanksFields.find(
    variant => variant.languageValue.slug === pageContext.lang
  )

  const langHeading =
    pageContext.lang === "pt_br" ? "Obrigado!" : thanks.thanksHeading

  return (
    <Layout>
      <Seo title={langHeading} />

      <Theme.Wrap size="narrow">
        <Styles2 dangerouslySetInnerHTML={{ __html: thanks.thanksCopy }} />
      </Theme.Wrap>
    </Layout>
  )
}

export const Query = graphql`query ThanksTemplate {
  page: wpPage(slug: { eq: "contact" }) {
    id
    contactFields {
      thanksFields {
        languageValue {
          slug
          name
        }
        thanksHeading
        thanksCopy
      }
    }
  }
}
`

export default ThanksTemplate
